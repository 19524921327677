import { Cancel } from "@mui/icons-material";
import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, useRef } from "react";

import styles from "./index.module.scss";

type IProps = Omit<TextFieldProps, 'onChange'> & {
	max?: number;
	showClear?: boolean;
    onChange: (value?: string) => void
};

export default function Input(props: IProps) {
	const { max, onChange, showClear } = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length > max!) {
			return;
		}
		onChange?.(event.target.value);
	};

  const handleClear = () => {
    onChange?.();
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

	return (
		<div className={styles.inputWrapper}>
			{/* @ts-ignore */}
			<TextField
				inputRef={inputRef}
				{...props}
				variant="outlined"
				onChange={handleChange}
			/>

			{showClear && inputRef.current?.value.length! > 0 && (
				<Cancel className="cancelIcon" onClick={handleClear} />
			)}
		</div>
	);
}
