import AuthRouter from "@/router/authRouter";
import routes from "@/router/routes";
import { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";

const RouteAuthFun = (routeList: any[]) => {
	return routeList.map(
		(item: {
			path: string;
			auth: boolean;
			element: ReactNode;
			children?: any;
			needAuth?: boolean;
			title?:string;
		}) => {
			return (
				<Route
					path={item.path}
					element={
						<AuthRouter key={item.path} title={item.title}>
							{item.element}
						</AuthRouter>
					}
					key={item.path}
				>
					{/* 递归调用，因为可能存在多级的路由 */}
					{item?.children && RouteAuthFun(item.children)}
				</Route>
			);
		}
	);
};

export default <Routes>{RouteAuthFun(routes)}</Routes>;
