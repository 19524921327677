import { NotificationEnum } from "@/constants/enums";
import { Popover, PopoverProps, Stack } from "@mui/material";
import { useRef, useState } from "react";
import styles from "./index.module.scss";
import Empty from "@/components/empty";
import { LibraryAddCheck, Sms } from "@mui/icons-material";
import { useRequest } from "ahooks";
import { getMsgList, readAllMsg, readMsg } from "@/api/feilianyun";
import { MsgItem } from "@/api/feilianyun/type";
import { isEmpty } from "lodash-es";
import MsgList from "./MsgList";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";


export interface UserCenterProps extends Omit<PopoverProps, "onClose"> {
	onClose?: () => void;
}

const MessageNotification = (props: UserCenterProps) => {

	const navigate = useNavigate()

	const [msgList, setMsgList] = useState<MsgItem[]>([]);
	const pageNoRef = useRef(1);
	const loadingRef = useRef(false);
	const totalRef = useRef(-1);

	const DEFAULT_QUERY = {
		page: 1,
		pageSize: 10,
		isRead: 0,
		messageType: NotificationEnum.SYSTEM
	};

	// 请求消息
	const { data, run: runGetMsgList } = useRequest(getMsgList, {
		defaultParams: [DEFAULT_QUERY],
		ready: !!props.anchorEl,
		onSuccess: (res) => {
			const _msgList: MsgItem[] = [];
			const { records = [], total } = res;
			totalRef.current = total;

			records.forEach((item) => {
				_msgList.push(item);
			});

            const pageNo = pageNoRef.current;
			setMsgList(
				pageNo === 1 ? _msgList : [...msgList, ..._msgList]
			);
		},
		onFinally: () => {
			loadingRef.current = false;
		},
	});

	const { run: runReadMsg } = useRequest(readMsg, {
        manual: true,
		onSuccess: () => {
			runGetMsgList(DEFAULT_QUERY)
		}
    })

	const { run: runReadAll } = useRequest(readAllMsg, {
		manual: true,
		onSuccess: () => {
			runGetMsgList(DEFAULT_QUERY)
		}
	})

	// 消息已读
	const handleRead = (msgId: number) => {
		runReadMsg(msgId)
	}

	/**
	 * 操作：加载更多
	 */
	const handleLoadMore = () => {
		if (loadingRef.current) return;
		const currentIndex = pageNoRef.current;
		const hasMore =
			currentIndex * DEFAULT_QUERY.pageSize < totalRef.current;
		if (hasMore) {
			loadingRef.current = true;
			pageNoRef.current = pageNoRef.current + 1;
			const req = {
				...DEFAULT_QUERY,
				page: pageNoRef.current,
			};
			runGetMsgList(req);
		}
	};

	const handleReadAll = () => {
		runReadAll()
	}

	const handleAll = () => {
		props.onClose?.()
		navigate('/personal-center?type=myMsgList')
	}

	return (
		<Popover
			{...props}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			anchorPosition={{ left: 0, top: 12 }}
			sx={{
				top: "10px",
			}}
		>
			<div className={styles.messageNotificationInner}>
				<div className={styles.notificationList}>
					<InfiniteScroll
						pageStart={0}
						initialLoad={false}
						loadMore={handleLoadMore}
						threshold={500}
						hasMore={data?.total! > msgList.length}
						useWindow={false}
					>
						{!isEmpty(msgList) ? (
							<MsgList data={msgList} onRead={handleRead}/>
						) : (
							<Empty content="没有未读的消息哦~" icon={<Sms />} />
						)}
					</InfiniteScroll>
				</div>
			</div>

			<div className={styles.messageNotificationFooter}>
				<div className={styles.readAll} onClick={handleReadAll}>
					<Stack direction="row" alignItems="center">
						<LibraryAddCheck />
						<h4>全部已读</h4>
					</Stack>
				</div>
				<div className={styles.checkAll} onClick={handleAll}>查看全部消息</div>
			</div>
		</Popover>
	);
};

export default MessageNotification;
