import createInstance from "@/api/instance";
import {
  LoginParams,
  MemberUserInfoData,
  RecordListData,
  UpdateData,
  UpdatePhone,
  UserInfoData,
  UserUpdate,
  WxBindPhoneData,
  WxLoginData, WxLoginSuccessData
} from "./type";

const instance = createInstance(process.env.REACT_APP_FEI_API!);

// 发送短信验证码-登录
export function sendVerificationCode(account: string, ack: string, x: number) {
    return instance<string>({
        method: 'GET',
        url: '/user/sendVerificationCode',
        params: {
          account,
          ack,
          x,
        },
    });
}

// 发送短信验证码-修改密码
export function setPassword() {
  return instance<string>({
      method: 'GET',
      url: `/user/update/verification/code`,
  });
}

// 短信验证码登录
export function loginVerifyCode(opt: LoginParams) {
  return instance<string>({
    method: 'POST',
    url: `/user/login/code`,
    data: opt
  });
}

// 密码登录
export function loginPassword(opt: LoginParams) {
  return instance<string>({
    method: 'POST',
    url: `/user/login/passwd`,
    data: opt
  });
}


// 获取用户信息
export function getUserInfo() {
  return instance<UserInfoData>({
    method: 'GET',
    url: '/user/info',
  });
}

// 获取用户会员信息
export function getMemberUserInfo() {
  return instance<MemberUserInfoData>({
    method: 'GET',
    url: '/member/info',
  });
}

// 退出登录
export function logOut() {
  return instance({
    method: 'GET',
    url: '/user/logout',
  });
}

/**
 * 更新用户信息
 * @param params
 * @returns
 */
export function userUpdate(data:UserUpdate) {
  return instance<UserUpdate>({
    method: 'POST',
    url: '/user/update',
    data
  });
}


//用户注销登陆
export function userLogout() {
  return instance({
    method: 'GET',
    url: '/user/logout',
  });
}

type pageParams={pageNum:number,pageSize:number};

/**
 *
 * 获取购买AI绘画次数记录
 */
export function timesQuery(params:pageParams) {
  return instance<RecordListData<any>>({
    method: 'GET',
    url: '/member/purchase/times/query',
    params
  });
}


/**
 * 获取会员购买记录
 *
 */
export function payRecord(params:pageParams) {
  return instance<RecordListData<any>>({
    method: 'GET',
    url: '/member/pay/record',
    params
  });
}

export const integralRecord = (data:any) => {
  return instance<RecordListData<any>>({
    method: 'POST',
    url: '/integral/record/query',
    data: data
  });
}

/**
 *
 * 获取解锁次数记录
 */
export function lockRecord(params:pageParams) {
    return instance<RecordListData<any>>({
        method: 'POST',
        url: '/keyword/sell/record/query',
        data: params
    });
}

/**
 * 获取用户的邀请记录
 * @param params  page pageSize
 * @returns
 */
export function inviteRecord(params:any) {
  return instance<RecordListData<any>>({
      method: 'POST',
      url: '/user/invite/record/query',
      data: params
  });
}

// 获取用户权限
export function getUserAuth() {
    return instance({
        method: 'GET',
        url: `/user/authority`,
    });
}

/**
 * 绘画次数历史记录
 *
 */
export function timesHistory(params:pageParams) {
  return instance<RecordListData<any>>({
    method: 'GET',
    url: '/member/times/history',
    params:{
      pageIndex:params.pageNum,
      pageSize:params.pageSize
    }
  });
}


/**
 * 修改用户信息
 */
export function updateUserInfo(params: UpdateData) {
  return instance<string>({
    method: 'POST',
    url: '/user/update',
    data: params
  });
}

// 修改用户密码
export function updatePassword(params: UpdatePhone) {
  return instance<string>({
    method: 'POST',
    url: '/user/update/password',
    data: params
  });
}

/** 微信扫码登录 */
export function wxQrLogin(params: WxLoginData) {
    return instance<WxLoginSuccessData>({
        method: 'GET',
        url: '/wx/callback',
        params
    })
}

/** 微信绑定手机号 */
export function wxUserBindPhone(params: WxBindPhoneData) {
    return instance({
        method: 'POST',
        url: '/user/bind/phone',
        data: params
    })
}

//上传oss空间
export function getStsTemporaryToken() {
  return instance<string>({
    method: 'GET',
    url: '/user/getStsTemporaryToken'
  });
}

export const webStatus = () => {
  return instance({
    method: 'GET',
    url: '/user/webStatus'
  });
};
