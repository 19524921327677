import React from 'react'
import {renderImperatively} from '../utils/renderImperatively'
import {ModalTypeMap} from "@mui/material/Modal/Modal";
import {Modal} from "@mui/material";

export type ModalShowProps = Omit<ModalTypeMap['props'], 'open'>;

export type DialogShowHandler = {
    close: () => void
}

export const closeFnSet = new Set<() => void>()

export function show(props: ModalShowProps) {
    const handler: DialogShowHandler = renderImperatively(
        // @ts-ignore
        <Modal
            {...props}
            onClose={(...args) => {
                closeFnSet.delete(handler.close)
                props.onClose?.apply(null, args)
            }}
        >
            {props.children}
        </Modal>
    )
    closeFnSet.add(handler.close)
    return handler
}