import { Pagination } from "../feilianyun/type"

export interface Feature {
    name: string
    selected: boolean
    value: number
    itemUrl: string
}

export interface CommonOption {
    optionId: number
    optionItemUrl: string
    optionName: string
}

export interface optionMenu {
    commonOptions: CommonOption[]
    optionMenuName: string
    optionMenuValue: number
}

export interface DreamWorkOptions {
    feature: Feature[] // 脸型
    batchSizes?: Feature[]
    materialType?: Feature[] // 模式
    optionMenus: optionMenu[] // 其他选项
}

export interface CommonOptionParam {
    optionMenuValue: number
    optionId: number
}

export interface CroppedConfig {
    x: number
    y: number
    w: number
    h: number
}

export interface CreateDreamWorkParams {
    croppedConfig?: CroppedConfig // 裁剪配置
    croppedImages?: string // 裁剪后的图片
    originalImages?: string // 原图
    maskImages?: string // 蒙版图片
    batchSize?: number // 生成数量
    feature: number // 脸型
    optionMenus: CommonOptionParam[] // 综合选项
}

export interface AiModelResultSpeedBatchResultUrl {
    code: string;
    resultUrl: string;
    status: number;
}
export interface AiModelResultSpeedDetail {
    batchResultUrls: AiModelResultSpeedBatchResultUrl[]
    batchSize: number
    code: string
    createTime: string
    height: number
    id: number
    status: number
    updateTime: string
    width: number
}

export interface AiModelResultProgress {
    code: string
    errorMessage: string
    runEndTime: number
    runStartTime: number
    status: number
    urlResult: string
}

export type ListReq = Pagination & {
    type?: number
}

export interface ListItem {
    code: string
    createTime: string
    height: number
    resultUrl: string
    status: number
    width: number
}

export interface OptionItem {
    name: string
    value: string
    hidden?: boolean
}
export interface AiModelDetail {
    id: number
    userId: number
    code: string
    createTime: string
    height: number
    urlResult: string
    status: number
    width: number
    options: OptionItem[] // 配置信息
    originalImages: string // 原图
}


/**
 *  1 ai模特、2数字脸生成、3人物背景生成，4婚纱数字化
 */
export enum UserCreateMemberType {
    Model = 1,
    DIGITAL_FACE,
    SCENE,
    DIGITAL_WEDDING_DRESS
}