import { MsgItem } from "@/api/feilianyun/type";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import styles from "./index.module.scss";
import { IconButton, Stack } from "@mui/material";
import { CircleNotifications } from "@mui/icons-material";
import dayjs from "dayjs";

interface IProps {
	data: MsgItem[];
    onRead: (msgId: number) => void
}
const MsgList = (props: IProps) => {
	const { data, onRead } = props;

    const handleMore = (item: MsgItem) => {
        // 消息已读 重新请求消息列表
        onRead(item.id)
        window.open(item.targetUrl, '_blank')
    }

	return (
		<div className={styles.msgList}>
			{data.map((item) => (
				<div className={styles.msgItem} key={item.id}>
					<div className={styles.header}>
						<Stack direction="row" alignItems="center">
							<IconButton>
                                <CircleNotifications />
                            </IconButton>
							<span className={styles.msgType}>{item.subject}</span>
						</Stack>
                        <div className={styles.createTime}>{dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
					</div>
					<div className={styles.content}>
						{item.content}
					</div>
					<div className={styles.footer} onClick={() => handleMore(item)}>
						去看看 <ArrowForwardIos />
					</div>
				</div>
			))}
		</div>
	);
};

export default MsgList;
