import { FullScreenImage } from "@/components/fullScreenImage/fullScreenImage";
import { FullScreenImageProps } from "@/components/fullScreenImage/interface";
import GlobalModal from "@/components/globalDialog/globalModal/globalModal";
import GlobalSnackbar from '@/components/globalDialog/globalSnackbar';
import copy from 'copy-to-clipboard';

// 预览大图
export const previewImage = (props: FullScreenImageProps) => {
    const currentModal = GlobalModal.show({
        children: <FullScreenImage {...props} onClose={() => {
            props.onClose?.();
            currentModal.close();
        }} />
    })
}

export const generateBlob = (url: string) => {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", url, true);
    xhr.responseType = 'blob';

    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            resolve(xhr.response)
        }

        xhr.onerror = (err) => {
            reject(err)
        }

        xhr.send();
    })
}

export const download = (blob: Blob, name?: string, type?: string) => {
    //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
    const url = window.URL.createObjectURL(blob)
    const aDom = document.createElement('a')
    aDom.href = url
    aDom.download = `${name ?? 'download-file'}${type ? '.' + type : ''}`
    aDom.target = '_blank'
    document.body.appendChild(aDom)
    aDom.click()
    document.body.removeChild(aDom)
}


// 下载文件
export const downloadByBlob = (url: string, name?: string, type?: string) => {
    generateBlob(url).then((res: any) => {
        download(res, name, type ?? 'jpeg')
    })
}

// 一件复制
export const copyToClipboard = (value: string) => {
    copy(value)
    GlobalSnackbar.show({
        icon: 'success',
        message: '复制成功'
    })
}

// 复制图片
export const copyImgToClipboard = (blob: Blob) => {
    const data = [
        new window.ClipboardItem({
            [blob!.type]: blob,
        } as Record<string, Blob>)
    ]

    navigator.clipboard.write(data).then(() => {
        GlobalSnackbar.show({
            icon: 'success',
            message: '复制成功'
        })
    })
}

// 阻止devtool
export const preventOpenDevtool = () => {
	if (process.env.NODE_ENV === 'production') {
		try {
			// @ts-ignore
			DisableDevtool({
				// ondevtoolopen: (type: string) => {
				// 	const info = 'devtool opened!; type =' + type;
				// 	alert(info);
				// },
				detectors: [
					// @ts-ignore
					DisableDevtool.DetectorType.DefineId,
					// @ts-ignore
					DisableDevtool.DetectorType.Size,
					// @ts-ignore
					DisableDevtool.DetectorType.DateToString,
					// @ts-ignore
					DisableDevtool.DetectorType.FuncToString,
					// @ts-ignore
					DisableDevtool.DetectorType.Debugger,
					// @ts-ignore
					DisableDevtool.DetectorType.Performance,
					// @ts-ignore
					DisableDevtool.DetectorType.DebugLib,
				],
				md5: '3568ed5cc8deffb0ea7f5ff039b414f6'
			});
		} catch (e) {
		}
	}
}

// 验证手机号码
export const validatePhone = (value?: string) => {
    const phonePattern =
        /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
    return phonePattern.test(value!)
}

export const allocateItems = (
    listData: Array<any>,
    columnCount: number,
    itemWidth: number,
    newSpacing: number
) => {
    const renderData: any = Array.from({
        length: columnCount || 1,
    }).map(() => ({
        height: 0,
        data: [],
    }));
    listData.forEach((item: any) => {
        const newRenderData = [...renderData];
        newRenderData.sort((pre, nex) => pre.height - nex.height);
        if (newRenderData[0]) {
            newRenderData[0]?.data && newRenderData[0].data.push(item);
            newRenderData[0].height +=
                (item.height / item.width) * (itemWidth - newSpacing || 8) +
                newSpacing || 8;
        }
    });
    return renderData;

}



export const base64ToBlob =(data = '', contentType ="image/jpeg", sliceSize = 512)=> {
    return new Promise((resolve, reject) => {
      let byteCharacters = atob(data);
      let byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = [];
        for (let i = 0; i < slice.length; i++) {
            byteNumbers.push(slice.charCodeAt(i));
        }
        // 8 位无符号整数值的类型化数组。内容将初始化为 0。
        // 如果无法分配请求数目的字节，则将引发异常。
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      let result = new Blob(byteArrays, {
        type: contentType
      })
      resolve(result);
    })
   }


   export const getCookie=(key:string)=> {

    if (document.cookie && key) {
      const cookieInfo = document.cookie;
      let arr = cookieInfo.replace(/;\s/g, ';').split(";");
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i].split("=");
        if (item[0] === key) {
          return item[1];
        }
      }
      return null;
    }
    return null;
  }

// 判断是否是移动端
export function isMobile() {
	try {
		document.createEvent("TouchEvent"); return true;
	} catch(e) {
		return false;
	}
}

export function isDef(value: any) {
	return value !== null && value !== undefined;
}

export function getLocalStorage<T>(name: string, defaultValue: T | null = null): T | null {
	let res: any = defaultValue;
	try {
		res = window.localStorage.getItem(name)
		if (res) {
			res = JSON.parse(res);
		}
		if (!isDef(res)) {
			res = defaultValue;
		}
	} catch (_e) {
		res = defaultValue;
	}
	return res;
}

export function setLocalStorage<T>(name: string, value: T) {
	try {
		const storage = JSON.stringify(value);
		window.localStorage.setItem(name, storage);
	} catch (_e) {}
}
