import { lazy } from "react";

const AiPaint = lazy(() => import("@/pages/ai/paint/paint"));
const AiPlaza = lazy(() => import("@/pages/ai/plaza"));
const AiPaintDetail = lazy(() => import("@/pages/ai/detail"));
const AiInviteRank = lazy(() => import("@/pages/ai/invite-rank"));

const PaintRouter = [
	{
		path: "/ai/paint",
		element: <AiPaint />,
		title: "AI创作-飞链云版图",
	},
	{
		path: "/ai/paint/:id",
		element: <AiPaintDetail />,
		title: "作品详情-飞链云版图",
	},
	{
		path: "/plaza",
		element: <AiPlaza />,
		title: "艺术展厅-飞链云版图",
	},
	{
		path: "/ai/inviteRank",
		element: <AiInviteRank />,
		title: "邀请用户排行榜-飞链云版图",
	}
];

export default PaintRouter;
