import * as utils from './index';
import React from "react";
import {currentNavigate, injectNavigate} from "@/hooks/useWithRouter";
import initBury from "@/utils/bury";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
	initBury();
    React.$utils = utils
    React.$currentNavigate = currentNavigate
    React.$injectCurrentNavigate = injectNavigate
}

