import { UserCreateMemberType } from "@/api/dreamwork/type";
import { createSlice } from "@reduxjs/toolkit";

const model = UserCreateMemberType.Model;
const face = UserCreateMemberType.DIGITAL_FACE;
const dress = UserCreateMemberType.DIGITAL_WEDDING_DRESS

const initialState: any = {
    createHistory: {
        [model]: {
            refresh: false//ai模特历史创作记录是否需要刷新
        },
        [face]: {
            refresh: false
        },
        [dress]: {
            refresh: false
        }
    }
};

export const slice = createSlice({
    name: 'Create',
    initialState,
    reducers: {
        setUpdateHistoryRefresh: (state, action) => {
            state.createHistory=action.payload;
        }
    }
})

export const createActions = slice.actions;
export default slice.reducer;
