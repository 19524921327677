import Root from "@/pages/root/root";
import CompetitionRouter from '@/router/routes/competitionRouter';
import PaintRouter from '@/router/routes/paintRouter';
import PersonalCenterRouter from '@/router/routes/personalRouter';
import { lazy } from "react";
import LabRouter from "./labRouter";
import MJRouter from './mjRouter';

const Home = lazy(() => import('@/pages/home/home'));
const Page404 = lazy(() => import("@/pages/404/404"));
const EnterpriseService = lazy(() => import("@/pages/enterprise-service"));

const routes =  [
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: '/',
                element: <Home />,
                title:"AI绘画-飞链云版图"

            },
            ...PersonalCenterRouter,
            ...PaintRouter,
            ...CompetitionRouter,
            ...LabRouter,
            ...MJRouter,
            // ...EditRouter,
            {
                path: '/enterprise-service',
                element: <EnterpriseService />
            },
        ]
    },
    {
        path: '*',
        element: <Page404 />
    }
]

export default routes
