import { Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./footerBar.module.scss";

import { COMMON_URL, PROTOCOL, QRCODE } from "@/constants/config";

export default function FooterBar() {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("lg"));
	const { pathname } = useLocation();

	return (
		<div className={styles.footerBar}>
			<div className={styles.footerInner}>
				<Grid container spacing={0} className={styles.footerTop}>
					<Grid item sm={12} md={12} lg={4} className={styles.left}>
						<div>官方QQ群：274220622</div>
						<div>官方邮箱：fly@feilianyun.cn</div>
						{pathname === "/" && (
							<>
								<div className={styles.policy}>
									<NavLink
										className={styles.item}
										to={PROTOCOL.privacyPolicy}
										target="_blank"
									>
										《隐私政策》
									</NavLink>
									<NavLink
										className={styles.item}
										to={PROTOCOL.user}
										target="_blank"
									>
										《用户协议》
									</NavLink>

									<NavLink
										className={styles.item}
										to={PROTOCOL.recharge}
										target="_blank"
									>
										《飞星充值协议》
									</NavLink>
								</div>
								<div className={styles.policy}>
									<NavLink
										className={styles.item}
										to={PROTOCOL.freePaint}
										target="_blank"
									>
										《飞链云免费创作协议》
									</NavLink>

									<NavLink
										className={styles.item}
										to={PROTOCOL.rebate}
										target="_blank"
									>
										《飞链云版图分销返佣协议》
									</NavLink>
								</div>
								<div className={styles.policy}>
									<NavLink
										className={styles.item}
										to={PROTOCOL.withdraw}
										target="_blank"
									>
										《钱包提现协议》
									</NavLink>

									<NavLink
										className={styles.item}
										to={PROTOCOL.keywords}
										target="_blank"
									>
										《飞链云关键词解锁购买与销售协议 》
									</NavLink>
								</div>
								<div className={styles.policy}>
									您已阅读知晓并同意本站内全部协议，如不同意，请立即关闭本站服务
								</div>
							</>
						)}
					</Grid>
					<Grid
						item
						container
						sm={12}
						md={12}
						lg={8}
						className={classNames(styles.right, {
							[styles.rightSmall]: matches,
						})}
						gap={6}
					>
						<div className={styles.rightItem}>
							<img src={QRCODE.wechat} alt="code" />
							<div className={styles.title}>飞链云官方微信群</div>
						</div>
						<div className={styles.rightItem}>
							<img src={QRCODE.customer} alt="code" />
							<div className={styles.title}>飞链云官方客服</div>
						</div>

						<div className={styles.rightItem}>
							<img src={QRCODE.officialAccount} alt="code" />
							<div className={styles.title}>微信官方公众号</div>
						</div>

						<div className={styles.rightItem}>
							<img src={QRCODE.miniProgram} alt="code" />
							<div className={styles.title}>飞链云官方小程序</div>
						</div>
					</Grid>
				</Grid>
				<Stack 	className={styles.footerBottom} direction="row">
					<Typography sx={{fontSize:"14px"}}>友情链接：</Typography>
					<NavLink to={COMMON_URL.GuanWang} target="_blank" rel="noopener noreferrer">
						飞链云官网
					</NavLink>
				</Stack>
				<Stack
					className={styles.footerBottom}
					direction="row"
					alignContent="center"
					spacing={2}
				>
					<NavLink to={COMMON_URL.ICP} target="_blank" rel="noopener noreferrer">
						浙ICP备2022026582号
					</NavLink>
					<NavLink
						to={COMMON_URL.publicSecurityFiling}
						target="_blank"
						rel="noopener noreferrer"
					>
						公安备案号：33010802012749
					</NavLink>
				</Stack>
			</div>
		</div>
	);
}
