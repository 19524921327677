import { Alert, AlertColor, Snackbar } from "@mui/material";
import { SnackbarProps } from "@mui/material/Snackbar";
import { renderImperatively } from '../utils/renderImperatively';

export type SnackbarShowProps = Omit<SnackbarProps, 'open'> & {
  icon: AlertColor
};

export type SnackbarShowHandler = {
    close: () => void
}

export const closeFnSet = new Set<() => void>();

export function show(props: SnackbarShowProps) {
    const handler: SnackbarShowHandler = renderImperatively(
        // @ts-ignore
        <Snackbar
            {...props}
            autoHideDuration={props.autoHideDuration === null ? null : 3000}
            anchorOrigin={props.anchorOrigin || {vertical: 'top', horizontal: 'center'}}
            onClose={(...args) => {
              closeFnSet.delete(handler.close)
              props.onClose?.apply(null, args)
            }}
        >
          <Alert severity={props.icon} sx={{ width: '100%' }}>
            {props.message}
          </Alert>
        </Snackbar>
    )
    closeFnSet.add(handler.close);
    return handler
}