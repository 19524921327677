import { ThemeOptions, createTheme } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#36d4c7',
        },
        secondary: {
            main: '#7851ec'
        }
    },
};

export const defaultTheme = createTheme(themeOptions);