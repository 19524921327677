import { lazy } from "react";

const MJPaint = lazy(() => import("@/pages/mj"))
const MJList = lazy(() => import("@/pages/mj/list"))
const MJDetail = lazy(() => import("@/pages/mj/detail"))

const MJRouter = [
    {
        path: '/mj',
        element: <MJPaint />,
        title: "MJ-飞链云版图"
    },
    {
        path: '/mj/list',
        element: <MJList />,
        title: "MJ-飞链云版图"
    },
    {
        path: '/mj/detail/:id',
        element: <MJDetail />,
        title: "MJ-飞链云版图"
    },
]

export default MJRouter