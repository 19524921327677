import { UserCenterProps } from "./interface";

import { logOut } from "@/api/user";
import { useAppSelector } from "@/app/hooks";
import { userActions } from "@/app/store/slice/user";
import ShareModal from "@/components/shareModal";
import { UserCenterURL } from "@/constants/config";
import { MemberLevel } from "@/constants/enums/member";
import { Edit, IosShare, Logout, Settings } from "@mui/icons-material";
import { IconButton, Popover, Stack } from "@mui/material";
import { useRequest } from "ahooks";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const memberTypeColor: Record<number, string> = {
	[MemberLevel.NORMAL]: "#e6e8ec",
	[MemberLevel.INTERNSHIP]: "#e6e8ec",
	[MemberLevel.BRONZE]: "#7d633f",
	[MemberLevel.SILVER]: "#777e90",
	[MemberLevel.GOLD]: "#b58854",
	[MemberLevel.PLATINUM]: "#7851ec",
};

const entranceList = [
	{
		icon: <img src={UserCenterURL.pay_record} alt="payRecord" />,
		text: "个人中心",
		link: "/personal-center",
	},
	{
		icon: <img src={UserCenterURL.task_center} alt="taskCenter" />,
		text: "任务中心",
		link: "/energy",
	},
	{
		icon: <img src={UserCenterURL.member_center} alt="memberCenter" />,
		text: "会员中心",
		link: "/member",
	},
	{
		icon: <img src={UserCenterURL.novice_tutorial} alt="memberCenter" />,
		text: "管理后台",
		link: process.env.REACT_APP_USER_MANAGE_HOST,
	},
];

export default function UserCenterPopover(props: UserCenterProps) {
	const navigate = useNavigate();
	const { onClose } = props;

	const { memberMetaInfo, nickname, collectInfo } = useAppSelector(
		(state) => state.user
	);

	const [open, setOpen] = useState<boolean>(false);

	const { run: runLogOut } = useRequest(logOut, {
		manual: true,
		onSuccess: () => {
			React.$store.dispatch(userActions.setUserLoginOut());
			navigate("/");
		},
	});

	const handleClickWrapper = (fn: Function) => {
		onClose?.();
		fn();
	};

	/**
	 * 跳转到关注列表或者粉丝列表
	 */
	const handleLinkToFollower = (type: number) => {
		onClose?.();
		navigate(`/follower?type=${type}`);
	};

	const extraEntranceList = [
		{
			icon: <IosShare />,
			text: "邀请好友",
			onClick: () => setOpen(true),
		},
		{
			icon: <Settings />,
			text: "密码设置",
			onClick: () =>
				handleClickWrapper(() => navigate("/personal-center/settings")),
		},
		{
			icon: <Logout />,
			text: "退出登录",
			onClick: () => handleClickWrapper(runLogOut),
		},
	];

	return (
		<Popover
			{...props}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			sx={{
				top: "10px",
			}}
		>
			<div className={styles.userCenterInner}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<div className={styles.userName}>{nickname}</div>
					<div
						className={styles.memberName}
						style={{
							backgroundColor:
								memberTypeColor[memberMetaInfo.memberType],
							color:
								memberMetaInfo?.memberType ===
								MemberLevel.NORMAL
									? "#999"
									: "#fff",
						}}
					>
						{!!memberMetaInfo.iconImg && (
							<img src={memberMetaInfo.iconImg} alt="iconImg" />
						)}
						<span className={styles.name}>
							{memberMetaInfo.memberTypeName}
						</span>
					</div>
					<IconButton
						onClick={() =>
							handleClickWrapper(() =>
								navigate("/personal-center/settings")
							)
						}
					>
						<Edit />
					</IconButton>
				</Stack>

				<Stack direction="row" alignItems="center" spacing={2}>
					<div
						className={styles.follow}
						onClick={() => handleLinkToFollower(0)}
					>
						关注 {collectInfo.follow}
					</div>
					<div
						className={styles.fans}
						onClick={() => handleLinkToFollower(1)}
					>
						粉丝 {collectInfo.fans}
					</div>
				</Stack>

				<Stack
					className="entranceWrapper"
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					{entranceList.map((item: any) => (
						<div
							className={styles.entranceItem}
							key={item.text}
							onClick={() => handleClickWrapper(() => {})}
						>
							{
								<NavLink
									to={item.link!}
									target={
										item.text === "管理后台"
											? "_blank"
											: "_self"
									}
								>
									{item.icon}
									<div className={styles.text}>
										{item.text}
									</div>
								</NavLink>
							}
						</div>
					))}
				</Stack>

				<Stack
					className="extraEntranceWrapper"
					direction="row"
					alignItems="center"
					spacing={2}
				>
					{extraEntranceList.map((item) => (
						<div
							className={styles.extraEntranceItem}
							key={item.text}
							onClick={item.onClick}
						>
							{item.icon}
							<div className={styles.text}>{item.text}</div>
						</div>
					))}
				</Stack>
			</div>

			<ShareModal
				open={open}
				onClose={() => handleClickWrapper(() => setOpen(false))}
			/>
		</Popover>
	);
}
