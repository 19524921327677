import initBury from './init';
import * as actions from './actions';
import React from "react";

export default function init() {
	initBury();
	React.$bury = {
		showPv: actions.sendPV,
		sendById: actions.sendById,
		stopBury: actions.stopBury,
		beginBury: actions.beginBury
	}
}
