

import { createFeedback } from "@/api/feilianyun";
import { TextField } from "@mui/material";
import { useRequest } from "ahooks";
import { useRef, useState } from "react";
import FlyModal from "../flyModal";
import GlobalSnackbar from "../globalDialog/globalSnackbar";
import { ErrorOption, FeedbackModalProps } from "./interface";

import  './sectionBox.scss';

export default function FeedbackModal(props: FeedbackModalProps) {

    const { open, feedback, onClose } = props
    const [errors, setErrors] = useState<ErrorOption>()

    const feedbackRef = useRef<HTMLInputElement>()

    const { run: runCreateFeedback } = useRequest(createFeedback, {
        manual: true,
        onSuccess: () => {
            GlobalSnackbar.show({
                icon: 'success',
                message: '提交成功'
            })
            onClose()
        },
        onError: onClose
    })

    const handleChange = (value: string) => {
        if (!!value) {
            setErrors({ value: false, message: '' })
        }
    }

    const handleOk = () => {
        if (!feedbackRef.current?.value) {
            setErrors({
                value: true,
                message: '反馈内容不能为空'
            })
            return
        }
        runCreateFeedback({
            feedbackContent: feedbackRef.current?.value,
            functionalName: feedback?.name,
            functionalCode: feedback?.value
        })
    }

    return (
        <FlyModal
            open={open}
            title="反馈"
            onClose={onClose}
            onOk={handleOk}
            className="feedbackModal"
        >
            <div className="tips">反馈的内容被采纳后将赠送一天免费绘画时长</div>
            <TextField
                fullWidth
                type="string"
                autoFocus={true}
                label={`请输入想要的${feedback?.name}`}
                error={errors?.value}
                helperText={errors?.message}
                inputRef={feedbackRef}
                onChange={(event) => handleChange(event.target.value)}
            />
        </FlyModal>
    )
}
