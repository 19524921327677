import { useAppSelector } from "@/app/hooks";
import KeepAlive from "@/components/keepAlive/KeepAlive";
import Layout from "@/components/layout/layout";
import {useOutlet} from "react-router-dom";

export default function Root() {
    const outlet = useOutlet();
    const {cacheRoutes, cacheKeys} = useAppSelector(state => state.keepAlive)

    return <Layout>
        <KeepAlive include={ cacheRoutes } keys={ cacheKeys }>
            { outlet }
        </KeepAlive>
    </Layout>
}
