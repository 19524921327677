import { UserMemberStatus, UserMemberType } from "@/constants/enums/member";
import { permissionMap } from "@/constants/enums/permissions";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isObject } from 'lodash-es';
export enum LoginStatus {
    unLogin,
    login,
    supplement_phone
}

type CollectInfo={
    attention: boolean;
    beCollect: number;
    beLikes:number;
    collect: number;
    fans: number;
    follow: number;
    likes: number;
}

export interface User  {
    token: string; // 用户token
    nickname: string; // 用户名称
    balance: string; // 用户余额
    headImg: string; // 用户头像
    phone: string | null; // 手机号
    count: number; // 绘图次数
    longCount: number; // 永久绘图次数
    shortCount: number; // 临时绘图次数
    paintTotalNum: number; // 用户画板的总数
    memberType: UserMemberType; // 会员类型
    memberTypeName: string; // 会员类型名称
    memberStatus: UserMemberStatus; // 会员状态
    memberMetaInfo: {[key: string]: any} // 会员其他信息avatar
    userPermissions: {[key: number]: number} // 用户权限
    inviterId?: string // 邀请人ID
    userId: number
    loginStatus: LoginStatus // 当前的登录状态
    unionId: string // 微信的唯一凭证
    isFetch:boolean,
    freeTime: number // 用户免费创作倒计时
    integral:number;//永久飞星
	tempIntegral:number;//临时飞星
    aiCreateMemberMetaInfo:any;//创作用户会员信息
    collectInfo:CollectInfo;    
}

const initialState: User = {
    token: '',
    nickname: '创作者',
    balance: '',
    headImg: 'https://osscdn.feilianyunnft.com/imgs/202211/8b4515d7be2f4e454f3916e70b09774d1cee5f29619f4dda6269a6c5c50b57bf.png',
    phone: null,
    count: 0,
    longCount: 0,
    shortCount: 0,
    paintTotalNum: 0,
    memberType: UserMemberType.normal,
    memberTypeName: '',
    memberStatus: UserMemberStatus.noMember,
    memberMetaInfo: {},
    userPermissions: {...permissionMap},
    inviterId: undefined,
    userId: -1,
    loginStatus: LoginStatus.unLogin,
    unionId: '',
    isFetch:false,//是否发送请求
    freeTime: 0,
    integral:0,
    tempIntegral:0,
    aiCreateMemberMetaInfo:{},      
    collectInfo:{
        beCollect: 0,
        beLikes: 0,
        collect: 0,
        fans: 0,
        follow: 0,
        likes: 0,
        attention:false
    }
};

export const slice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUserMemberType: (state, action) => {
            state.memberType = action.payload
        },
        setUserMemberStatus: (state, action) => {
            state.memberStatus = action.payload
        },
        setUserPermissions: (state, action) => {
            state.userPermissions = {
                ...state.userPermissions,
                ...action.payload
            }
        },
        setUserPaintTotalNumber: (state, action) => {
            state.paintTotalNum= action.payload
        },
        setUserMemberMetaInfo: (state, action) => {
            state.memberMetaInfo = action.payload
        },
        setInviterId: (state, action) => {
            state.inviterId = action.payload
        },
        setUserLoginOut: () => {
            localStorage.removeItem("fly_user_token");
            return initialState;
        },
        setUserLoginStatus: (state, action: PayloadAction<LoginStatus>) => {
            state.loginStatus = action.payload;
        },
        setUserUnionId: (state, action) => {
            state.unionId = action.payload;
        },
        setUserInfo:(state, action) => {
            const newValue:any=action.payload;
            if(isObject(newValue)){
                const newState=Object.assign(state,newValue);
               return newState;
            }
        }

    }
})

export const userActions = slice.actions;


export default slice.reducer;
