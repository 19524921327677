// 新功能上线弹窗
import { getVersionUpdate, unsubscribe } from "@/api/feilianyun";
import { Button } from "@mui/material";
import { useRequest } from "ahooks";

import classNames from "classnames";
import { isEmpty } from 'lodash-es';
import { useState } from "react";
import styles from "./index.module.scss";

const NewFeatureModal = () => {

	const [open, setOpen] = useState<boolean>(false);

	// 接口请求是否更新弹窗
	const { data } = useRequest(getVersionUpdate, {
		onSuccess: (res) => {
			setOpen(!isEmpty(res));
		}
	})

	// 退订消息通知
	const { run: runUnsubscribe } = useRequest(unsubscribe, {
		manual: true,
		onSuccess: () => setOpen(false)
	})

	return (
		<div className={classNames(styles.versionUpdateModal, {
			[styles.open]: open
		})}>
			<div className={styles.title}>
				<img
					src="//aicdn.feilianyun.cn/statics/ai-paint-pc/images/home/mess.png"
					alt="newFeature"
				/>
			</div>
			<div className={styles.subTitle}>飞链云{data?.title}🎉🎉</div>

			<pre className={styles.content}>{data?.content!}</pre>
            <div className={styles.footer}>
                <Button variant="outlined" onClick={runUnsubscribe}>知道了</Button>
            </div>
		</div>
	);
};

export default NewFeatureModal;
