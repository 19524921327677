import { useAppSelector } from "@/app/hooks";
import { useMemo } from "react";
/**
 * 需要使用登录的方法
 * @param fn
 * @param deps
 */
export default function useUser() {
	const { userId, aiCreateMemberMetaInfo} = useAppSelector(
		(state) => state.user
	);

	const hasLogin = useMemo(() => {
		return userId !== -1;
	}, [userId]);

	const getCreateMember = (type: number) => {
		return aiCreateMemberMetaInfo[type];
	};
	
	return {
		userId,
		hasLogin,
		getCreateMember
	};
}
