import * as API from "@/api";
import { getMemberUserInfo, getUserInfo } from "@/api/user";
import { userActions } from "@/app/store/slice/user";
import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
import { LoginProps } from "@/components/loginBox/interface";
import Login from "@/components/loginBox/loginBox";
import { PermissionLevel, PermissionType } from "@/constants/enums/permissions";
import { USER_TOKEN } from "@/constants/localStorage";
import { addPermission, removePermission } from "@/service/permissions";
import { getCookie } from '@/utils';
import React from "react";

// 调用登录弹窗
let isOpenLogin = false;
export const openLoginModal = (props: LoginProps = {}) => {
	if (isOpenLogin) return Promise.resolve(true);
	return new Promise((resolve, reject) => {
		isOpenLogin = true;
		const dialog = GlobalDialog.show({
			children: (
				<Login
					{...props}
					onSuccess={() => {
						isOpenLogin = false;
						dialog.close();
						props.onSuccess?.();
						resolve(null);
					}}
				/>
			),
			onClose: () => {
				isOpenLogin = false;
				// reject(false);
			},
		});
		return dialog;
	});
};

// 用户粉丝/关注/收藏/被收藏数等数据
export const getUserDataInfo = async (userId?:any) => {
	return API.feilianyun.pc_personalInfo(userId);
}

export const baseUserInfo = () => {
	return getUserInfo().then(async (res) => {
		if (res) {
			const {
				nickname,
				phone,
				integral = 0,//永久积分
				tempIntegral = 0,//临时积分
				headImg,
				balance,
				id,
				freeTime
			} = res;

			const userDataInfo = await getUserDataInfo(id);

			React.$store.dispatch(
				userActions.setUserInfo({
					phone,
					paintTotalNum: (integral + tempIntegral),
					headImg,
					nickname,
					balance,
					freeTime,
					integral,
					tempIntegral,
					isFetch: true,
					userId: id,
					collectInfo:userDataInfo//粉丝/点赞等数据
				})
			);
		}
	});
}

// 获取用户信息
export function setUserInfoService() {
	setUserMemberInfo();
	setUserPermission();
	return baseUserInfo();
}

// 设置用户权限
export function setUserPermission() {
	return API.user.getUserAuth().then((res) => {
		if (res?.batchPictureExpand) {
			addPermission(PermissionType.batchPictureExpand, [
				PermissionLevel.read,
				PermissionLevel.write,
			]);
		} else {
			removePermission(PermissionType.batchPictureExpand, [
				PermissionLevel.read,
				PermissionLevel.write,
			]);
		}
	});
}

// 获取并保存用户会员信息
export function setUserMemberInfo() {
	return getMemberUserInfo().then((res) => {
		if (res) {
			React.$store.dispatch(
				userActions.setUserMemberType({
					memberTypeName: res.memberTypeName,
					memberType: res.memberType,
				})
			);
			React.$store.dispatch(userActions.setUserMemberStatus(res.status));
			React.$store.dispatch(
				userActions.setUserMemberMetaInfo({
					...res,
				})
			);
		}
	});
}

// 设置邀请人的ID
export function setInviterId(inviterId: number) {
	React.$store.dispatch(userActions.setInviterId(inviterId));
}

// 判断是否登录了
export function isLogin() {
	return !!React.$store.getState().user.phone;
}

export function pageInitService() {
	return setUserInfoService();
}

export function pageInitIfUserUnLoginService() {
	const cookie = getCookie(USER_TOKEN);
	if (cookie) {
		localStorage.setItem(USER_TOKEN, cookie);
	}

	const fly_user_token = localStorage.getItem(USER_TOKEN);

	if (fly_user_token) {
		return pageInitService();
	}

	return Promise.resolve();
}
