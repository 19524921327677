import {FullScreenImageProps} from "@/components/fullScreenImage/interface";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import styles from './fullScreenImage.module.scss';
import {useCallback, useState} from "react";
import TouchMidScrollView from "@/components/touchMidScrollView/touchMidScrollView";

export function FullScreenImage(props: FullScreenImageProps) {
    const {current, urls} = props;
    const [imgUrl, setImgUrl] = useState(current);

    const handleClose = useCallback(() => {
        props.onClose?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeImg = useCallback((url: string) => {
        setImgUrl(url)
    }, [])

    return <div className={styles.fullScreenImage}>
        <div className={styles.mainImage} style={{backgroundImage: `url(${imgUrl})`}}></div>
        <div className={styles.otherImageBox}>
            <TouchMidScrollView>
                <div className={styles.otherImageWrapper}>
                    {
                        urls?.map(url => {
                            return <div className={styles.otherImage} style={{backgroundImage: `url(${url})`}} onClick={handleChangeImg.bind(null, url)}></div>
                        })
                    }
                </div>
            </TouchMidScrollView>
        </div>
        <div className={styles.closeBox}>
            <CloseFullscreenIcon className={styles.closeBtn} onClick={handleClose}/>
        </div>
    </div>
}