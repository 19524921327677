import { COMMON_URL } from "@/constants/config";
import styles from "./index.module.scss";

interface IProps {
	icon?: string | React.ReactNode;
	content?: React.ReactNode;
}

export default function Empty(props: IProps) {
	const {
		icon = COMMON_URL.empty_data,
		content = "暂无数据",
	} = props;

	return (
		<div className={styles.flyEmptyWrapper}>
			{
				typeof icon === 'string' ? <img src={icon} alt="emptyData" /> : icon
			}

			<div className={styles.emptyContent}>{content}</div>
		</div>
	);
}
