import GlobalSnackbar from "@/components/globalDialog/globalSnackbar";
import { USER_TOKEN } from "@/constants/localStorage";
import { openLoginModal } from "@/service/user";
import axios, { AxiosRequestConfig, CreateAxiosDefaults } from "axios";

export default function createInstance(
	baseURL: string,
	config: CreateAxiosDefaults = {}
) {
	const instance = axios.create({
		withCredentials: true,
		baseURL,
		...config,
	});

	instance.interceptors.request.use(
		(configs) => {
			configs.headers["fly_user_token"] =localStorage.getItem(USER_TOKEN);
			return configs;
		},
		(err) => Promise.reject(err)
	);
	
	instance.interceptors.response.use(
		(res: any) => {
			const { data: { code, message, data } } = res;
			if (code === 200) {
				return data;
			} else if (code === 9995) {
				openLoginModal();
				localStorage.removeItem(USER_TOKEN);
				return Promise.reject();
			}  else {
				GlobalSnackbar.show({
					icon: "error",
					message: message || "服务器错误",
				});
				return Promise.reject(res.data.message);
			}
		},
		(err) => {
			return Promise.reject(err);
		}
	);
	return <T = any>(conf: AxiosRequestConfig = { method: "GET" }) => {
		return instance.request<T>(conf) as Promise<T>;
	};
}

export const instance = createInstance(process.env.REACT_APP_FEI_API!);