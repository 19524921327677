import { mjEnable } from '@/api/mj';
import useUser from "@/hooks/useUser";
import { Menu, MenuItem } from "@mui/material";
import update from "immutability-helper";
import { isBoolean } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
import { MoreLinkProps } from "./interface";

const LinkList = [
	{
		name: "艺术展厅",
		href: "/plaza",
	},
	{
		name: "AI大赛",
		href: "/ai/competition",
	},
	{
		name: "邀请排行榜",
		href: "/ai/inviteRank",
	},
	{
		name: "企业服务",
		href: "/enterprise-service",
	},
];

const MoreLinkPopover = (props: MoreLinkProps) => {
	const { anchorEl, onClose } = props;
	const [mjAuth, setMjAuth] = useState(false);
	const { hasLogin } = useUser();
	
	useEffect(() => {
		if (hasLogin) {
			mjEnable().then(res => {
				if (isBoolean(res)) {
					setMjAuth(res);
				}
			})
		}
	}, [hasLogin])

	const newLinkList=useMemo(()=>{

		if(mjAuth){
			const list:any={
				name: "MJ绘图",
				href: "/mj",
			};
			const newList=update(LinkList,{$splice:[[1,0,list]]});
			return newList;
		}
		return LinkList;
	},[mjAuth])

	return (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={onClose}
			MenuListProps={{
				"aria-labelledby": "basic-button",
			}}
		>
			{newLinkList.map((item) => (
				<MenuItem onClick={onClose} key={item.href}>
					<NavLink
						to={item.href}
						className={({ isActive }) =>
							isActive ? styles.linkActive : styles.link
						}
					>
						{item.name}
					</NavLink>
				</MenuItem>
			))}
		</Menu>
	);
};

export default MoreLinkPopover;
